import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaGithub,
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarker,
} from 'react-icons/fa';
import './Footer.scss';
import { office_email, office_location, office_phone } from 'const';
import { NavLogo } from 'components/Header/Header';
import { NavLink } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="bg-white border-t border-gray-200 text-gray-600">
      <div className="container mx-auto px-4 pt-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="mb-6">
            <NavLogo />
            <p className="my-1">
              Expertly trained, battle-tested, elite software developers on
              demand
            </p>
          </div>
          <div className="mb-6">
            <h5 className="text-xl font-bold mb-3">Contact</h5>
            <ul className="list-none footer-links">
              <li className="mb-2">
                <span className="flex items-center">
                  <FaPhoneAlt className="mr-2" />
                  {office_phone}
                </span>
              </li>
              <li className="mb-2">
                <span className="flex items-center">
                  <FaEnvelope className="mr-2" />
                  {office_email}
                </span>
              </li>
              <li className="mb-2">
                <span className="flex items-start">
                  <FaMapMarker className="mr-2 mt-1" />
                  {office_location}
                </span>
              </li>
            </ul>
          </div>
          <div className="mb-6">
            <h5 className="text-xl font-bold mb-3">Services</h5>
            <ul className="list-none footer-links">
              <li className="mb-2">
                <NavLink
                  className="hover:text-primary"
                  to="/services/web-development"
                >
                  Web Development
                </NavLink>
              </li>
              <li className="mb-2">
                <NavLink
                  className="hover:text-primary"
                  to="/services/app-development"
                >
                  Mobile App Development
                </NavLink>
              </li>
              <li className="mb-2">
                <NavLink className="hover:text-primary" to="/services/design">
                  UI/UX Design
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="mb-6">
            <h5 className="text-xl font-bold mb-3">Company</h5>
            <ul className="list-none footer-links">
              <li className="mb-2">
                <NavLink className="hover:text-primary" to="/about">
                  About Us
                </NavLink>
              </li>
              <li className="mb-2">
                <NavLink className="hover:text-primary" to="/contact">
                  Contact Us
                </NavLink>
              </li>
            </ul>

            <h5 className="text-xl font-bold my-4">Follow us</h5>
            <div className="flex justify-start lg:justify-start">
              <a href="#" className="mr-6 text-gray-400 hover:text-primary">
                <FaFacebookF className="text-xl" />
              </a>
              <a href="#" className="mr-6 text-gray-400 hover:text-primary">
                <FaTwitter className="text-xl" />
              </a>
              <a href="#" className="mr-6 text-gray-400 hover:text-primary">
                <FaLinkedinIn className="text-xl" />
              </a>
              <a href="#" className="text-gray-400 hover:text-primary">
                <FaGithub className="text-xl" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200 mt-8">
        <p className="text-center text-gray-600 py-4 text-sm">
          © {new Date().getFullYear()} ArqTech. All rights reserved.
        </p>
      </div>
    </footer>
  );
};
