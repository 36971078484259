import { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Header from './components/Header/Header';
import { Footer } from 'components/Footer/Footer';
import './App.scss';

const Home = lazy(() => import('./pages/Home/Home'));
const About = lazy(() => import('./pages/About/About'));
const Services = lazy(() => import('./pages/Services/Services'));
const ServiceDetails = lazy(
  () => import('./pages/ServiceDetails/ServiceDetails')
);
const ContactPage = lazy(() => import('./pages/ContactPage/ContactPage'));
const TechnologiesPage = lazy(
  () => import('./pages/TechnologiesPage/TechnologiesPage')
);

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="font-sans text-gray-900 antialiased">
      <Toaster />
      <Header />

      <Suspense fallback={<div className="text-center mt-4">Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:slug" element={<ServiceDetails />} />
          <Route path="/technologies" element={<TechnologiesPage />} />
          <Route path="/contact" element={<ContactPage />} />

          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Suspense>

      <Footer />
    </div>
  );
}

export default App;
