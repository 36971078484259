// Header.tsx
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './Header.scss';

export const NavLogo = () => (
  <Link to="/" className="text-2xl font-bold flex w-min">
    <img
      src="/arqtech-logo.svg"
      height="25"
      width="35"
      className="mx-auto"
      alt=""
    />
    <CompanyTitle />
  </Link>
);

export const CompanyTitle = ({ bold }: { bold?: boolean }) => (
  <span className={`${bold ? 'font-semibold' : ''} text-black`}>
    <span className={`text-primary ml-1}`}>Arq</span>
    Tech
  </span>
);

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getNavLinkClass = (isActive: boolean): string => {
    return `block mt-4 lg:inline-block lg:mt-0 mr-4 uppercase hover:text-primary ${
      isActive ? 'text-primary' : ''
    }`;
  };

  const handleLink = () => setIsMenuOpen(false);

  return (
    <header
      className={`${
        isScrolled ? 'sticky top-0 bg-white shadow-lg' : 'bg-white'
      } transition-all duration-300 ease-in-out text-black border-b-slate-300 border relative z-50`}
    >
      <nav className="container mx-auto flex flex-wrap items-center justify-between p-3">
        {/* Hamburger Menu Button */}
        <NavLogo />
        <button
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
            // setIsScrolled(!isScrolled);
          }}
          className="text-black text-3xl lg:hidden focus:outline-none focus:ring-2 focus:ring-gray-600"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>

        {/* Links and Contact Us Button */}
        <div
          className={`${isMenuOpen ? 'block' : 'hidden'} w-full lg:flex lg:items-center lg:w-auto animated-menu transform transition ease-in-out duration-300 animated fadeIn`}
          style={{ animation: 'fadeIn 0.5s' }}
        >
          <div className="text-sm lg:flex-grow">
            <NavLink
              className={({ isActive }) => getNavLinkClass(isActive)}
              to="/about"
              onClick={handleLink}
            >
              About Us
            </NavLink>
            <NavLink
              className={({ isActive }) => getNavLinkClass(isActive)}
              to="/services"
              onClick={handleLink}
            >
              Services
            </NavLink>
            <NavLink
              className={({ isActive }) => getNavLinkClass(isActive)}
              to="/technologies"
              onClick={handleLink}
            >
              Technologies
            </NavLink>
          </div>
          <div>
            <Link
              to="/contact"
              onClick={handleLink}
              className="inline-block text-sm px-4 py-2 leading-none border rounded text-primary border-primary hover:border-transparent hover:text-white hover:bg-primary mt-4 lg:mt-0"
            >
              CONTACT US
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
